$error: #d41616;

textarea,input, button {
  outline: none !important; }
textarea {
  resize: none; }
ul, ol {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.flex-alignCenter {
  align-items: center !important; }
.flex-justifyStart {
  justify-content: initial !important; }
.flex-justifyCenter {
  justify-content: center !important; }
.flex {
  display: flex !important; }
.defFullWidth {
  width: 100% !important; }
.w150 {
  max-width: 150px !important;
  width: 100%; }
.w180 {
  max-width: 180px !important;
  width: 100%; }
.w500 {
  max-width: 500px !important;
  width: 100%; }
.w200 {
  max-width: 200px;
  width: 100%; }
.widthUnset {
  width: unset !important; }

.commentOrder {
  width: 100%;
  height: 100px;
  color: rgb(51, 51, 51) !important;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.25rem;
  padding: 10px; }

.tarea-row {
  display: flex;
  justify-content: space-between !important;
  &-block {
    width: 100%;
    div {
      color: rgb(204, 204, 204);
      white-space: nowrap;
      font-size: 12px;
      text-align: left; } }
  & > div:not(:last-of-type) {
    margin-right: 8px; } }

.reactSelectTop {
  .Select-menu-outer {
    top: auto !important;
    bottom: 100% !important;
    margin-top: 0px !important;
    margin-bottom: -1px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    box-shadow: none !important; }
  .Select-control {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important; } }

.def-btnC {
  color: #828282; }
.def-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all 0.25s;
  .filter {
    z-index: 11; }
  & > div {
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    margin-right: 15px;
    .filter , button {
      margin-right: 5px; } }
  .def-control, .def-filters {
    z-index: 11;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.35rem; }
  .def-control button, .def-filters button {
    flex: 0;
    height: 35px; }
  .def-control .panel-container__start, .def-filters .panel-container__start {
    margin-left: 0; }
  .def-control {

    .icon-button {
      width: 50px; } }
  .def-filters .filter {
    max-width: 220px;
    min-width: 150px;
    width: 100%;

    &.wider {
      max-width: 440px;
      min-width: 320px;
      width: 100%; } } }

.Select-clear-zone {
  display: none  !important; }

input.def-input_field {
  min-height: 35px !important;
  max-height: 35px !important; }

// --- Right bar (переписать на нативную верстку)
.header-fixed .app-header {
  z-index: 1089 !important; }
.def-rightBar {
  right: 0;
  top: 0;
  position: fixed;
  z-index: 1088 !important;
  height: 100% !important;
  padding: 60px 10px 0 10px;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
  width: 250px;
  color: #2f353a;
  background: #fff;
  border-left: 1px solid #c8ced3;
  .Select-clear-zone {
    display: none !important; } }

.wdef-title_row_full {
  &::before {
    display: none; }
  &::after {
    margin-left: 0; } }

.filter-onerow {
  display: flex;
  align-items: center;
  label.def-chbx_filter_checkedWindow {
    border-color: rgb(55, 148, 87) !important;
    &::after {
      position: absolute;
      width: 10px;
      height: 10px;
      content: ' ';
      background: rgb(58, 157, 93);
      border-radius: 2px;
      top: 4px;
      left: 4px; } }

  label.def-chbx_filter_checked {
    background: url('../../assets/icons/checked.svg')no-repeat center;
    background-size: 70%; }
  label {
    position: relative;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #e4e7ea;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 5px;
    padding: 6px;
    input[type='checkbox'] {
      display: none; } }
  p {
    user-select: none;
    margin-bottom: 0;
    margin-right: 15px; } }

.AppHeader-right {
  display: flex;
  align-items: center;
  .header-manager {
    margin: 0 5px; }
  & > button {
    min-width: 50px;
    min-height: 27px; } }
.logout {
  color: #73818f;
  transition: 0.2;
  font-size: 1.09375rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  min-height: 22px;
  min-width: 50px;
  &:hover {
    opacity: 0.8; } }
// errors
.def {
  &_tabErr {
    border-color: red !important;
    border-bottom-color: transparent !important; }
  &-wrap_span {
    width: 100%;
    position: relative; }
  &-error_inp {
    border-color: #d41616 !important; }
  &-error_select .Select-control {
    border-color: #d41616 !important; }
  &-error_selectAjax {
    border-color: #d41616 !important; } }

// --  окно
.wdef-main {
  overflow-x: hidden;
  min-height: 100%;
  background-color: rgb(255, 255, 255);
  &.photo-fix-add {
    height: 100%;
    display: grid;
    grid-template-rows: auto 64px; }
  input, .Select-placeholder {
    color: #333 !important; }
  .def_row100 {
    width: 100%;
    position: relative; }
  .max-size {
    height: initial; }
  .tab-content .tab-pane {
    padding-bottom: 0; }
  .wdef-beetwen {
    padding: 6px 1rem;
    justify-content: space-between;
    align-items: center; }
  .Select-clear {
    display: none !important; }
  .filter-row {
    width: 100%; }
  label {
    white-space: nowrap;
    margin-right: 15px; }
  .wdef-select_row {
    .react-datepicker-wrapper, .react-datepicker-wrapper div {
      width: 100%;
      input {
        width: 100%;
        height: 34px !important;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400; } } }

  .wdef-title_row {
    font-size: 17px;
    width: 100%;
    margin-bottom: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &_info {
      font-size: 16px;
      text-shadow: 0 0px 1px black;
      font-style: italic;
      margin-left: 7px; }
    &::before,  &::after {
      background: #c7c7c7;
      content: '';
      min-height: 1px;
      max-height: 1px; }
    &::before {
      margin-right: 10px;
      width: 30px; }
    &::after {
      width: 100%;
      margin-left: 10px; } }


  // old
  .order-info-card {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    width: 100%;
    .order-info-card__wrapper {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      border: 1px solid lightgray;
      padding: 10px;
      margin-bottom: 10px; }
    .order-info-card__footer {
      display: flex;
      width: 100%;
      align-items: center; } }

  .fields-container > div {
    margin-bottom: 10px; }

  .fields-container__row {
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center; }
    .react-datetime-picker {
      display: flex; } }

  .filter-onerow {
    display: flex;
    align-items: center;
    label.def-chbx_filter_checked {
      background: url('../../assets/icons/checked.svg')no-repeat center;
      background-size: 70%; }
    label {
      width: 20px;
      height: 20px;
      background: #fff;
      border: 1px solid #e4e7ea;
      border-radius: 0.25rem;
      cursor: pointer;
      margin-bottom: 0px;
      margin-top: 5px;
      input[type='checkbox'] {
        display: none; } }
    p {
      user-select: none;
      margin-bottom: 0;
      margin-right: 15px; } }

  .wdef-select_row {
    display: flex;
    label {
      white-space: nowrap;
      margin-right: 15px; } } }


// ---  модальное окно согласия
.modalAgree {
  overflow: hidden;
  .MuiDialog-paperWidthSm {
    width: 550px; }
  &_progress {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    margin: 0 !important; }
  &_footer.error {
    justify-content: center;
    .modalAgree_footer_ok.error {
      background-color: rgb(220, 0, 78); } }
  &_footer.success {
    justify-content: center;
    .modalAgree_footer_ok.success {
      background-color: #4caf50; } }
  &_footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 20px 16px 20px;
    &_ok {
      width: 40px;
      height: 40px;
      margin-bottom: 20px; }
    &_ag.success {
      color: #fff;
      background-color: #4caf50;
      width: 100%;
      display: flex;
      justify-content: center; }
    &_ag.error {
      color: #fff;
      background-color: rgb(220, 0, 78); }
    &_ag {
      color: rgb(220, 0, 78);
      &:hover {
        color: #ba0142; } } }
  p, h6 {
    max-width: 500px;
    text-align: center;
    margin-bottom: 0; } }


// --- эффекты при прокручивании

.Tscroll_titles {
  white-space: nowrap !important; }
.Tscroll_titles.active {
  height: 32px;
  position: fixed;
  top: calc(55px + 60px);
  background: #ffffff;
  z-index: 4;
  tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #c8ced3;
    border-top: 1px solid #c8ced3;
    th {
      text-align: center;
      padding: 0.3rem;
      border: none; } } }

.Tscroll_filters.active {
  transition: none;
  padding: 10px 0;
  z-index: 6;
  position: fixed;
  top: 55px;
  height: 60px;
  background: #ffffff;
  .Select-multi-value-wrapper {
    min-height: 34px;
    max-height: 34px;
    overflow: hidden; } }


// --- дефолтные значения
input.greyColor {
  color: #bcbcbc !important;
  border-color: #bcbcbc !important; }
.drv_choose_car_comment {
  width: 100%; }
.def-infoText {
  label {
    font-weight: 600; }
  &-info {
    display: flex;
    align-items: center;
    &-ok {
      fill: #15a415; }
    &-no {
      fill: #932121; } } }
.def-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all 0.25s;
  & > div {
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    margin-right: 15px;
    .filter , button {
      margin-right: 5px; } }
  .def-control, .def-filters {
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.35rem; }
  .def-btn_print {
    border-color: #d9d9d9;
    &:hover {
      background-color: transparent;
      border-color: #333;
      svg {
        fill: #333 !important; } }
    svg {
      fill: #828282; } }
  .def-btn_center {
    width: 50px !important; }
  .def-control button, .def-filters button {
    flex: 0;
    height: 35px; }
  .def-control .panel-container__start, .def-filters .panel-container__start {
    margin-left: 0; }
  .def-control {
    .icon-button {
      width: 50px; } }
  .def-filters .filter {
    max-width: 220px;
    min-width: 150px;
    width: 100%; }
  .Select-clear-zone {
    display: none; }
  .def-unput_err {
    border-color: #d41616 !important; } }

input.def-input_field {
  height: 34px !important; }

// --- Right bar (переписать на нативную верстку)
.header-fixed .app-header {

  padding: 0 10px 0 10px;
  z-index: 1089 !important; }
.def-rightBar {
  right: 0;
  top: 0;
  position: fixed;
  z-index: 1088 !important;
  height: 100% !important;
  padding: 60px 10px 0 10px;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
  width: 250px;
  color: #2f353a;
  background: #fff;
  border-left: 1px solid #c8ced3; }

.filter-onerow {
  display: flex;
  align-items: center;
  label.def-chbx_filter_checked {
    background: url('../../assets/icons/checked.svg')no-repeat center;
    background-size: 70%; }
  label {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 5px;
    input[type='checkbox'] {
      display: none; } }
  p {
    user-select: none;
    margin-bottom: 0;
    margin-right: 15px; } }

// -- table
.mainTableScroll {
  width: 100%;
  .ps__rail-y {
    display: none !important; }
  .ps__rail-x {
    bottom: 0;
    left: unset !important;
    position: fixed; } }

.table-responsive {
  overflow-x: initial !important; }
.table {
  &-status {
    white-space: nowrap;
    padding: 3px 6px;
    color: #fff;
    border-radius: 8px; }
  &-to {
    position: relative;
    span {
      display: block;
      height: 6px;
      width: 6px;
      position: absolute;
      top:  calc(50% - 3px);
      left:  calc(50% - 3px);
      background-color: #1976d2;
      border-radius: 100%; } }
  th {
    white-space: nowrap;
    font-weight: 600; }
  .lastMin {
    color: $error; }
  &-param {
    color: #0a6cbc;
    font-weight: 500; }
  td, th {
    vertical-align: middle;
    text-align: center; } }

.tdNoWrap {
  white-space: nowrap; }
.td--200 {
  min-width: 200px;
  max-width: 200px; }
.td--45 {
  min-width: 45px;
  max-width: 45px; }

// --- react  мульти инпут
.react-tagsinput-input {
  width: 151px !important; }


// --- заказы
.deff_payment {
  min-width: 105px;
  max-width: 105px; }
.datePanel {
  height: 34px;
  &_from {
    margin-right: 6px; }
  &_to {
    margin-left: 6px;
    margin-right: 6px; }
  & > span {
    display: flex;
    align-items: flex-end; }
  & > div {
    width: 85px;
    display: flex;
    align-items: flex-end; }

  &.withTime > div {
    width: 125px; }

  &_picker {
    color: #5c6873;
    width: 85px;
    text-align: center;
    border: none;
    border-bottom: 1px solid #b3b3b3; }

  &_picker.withTime {
    width: 125px; } }

// react-tell
.react-tell.error {
  border-color: $error !important; }

// --- audits журналы
.audit {
  &_panel {
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    & > div:not(:last-of-type) {
      margin-right: 15px; }
    &_btn {
      margin-right: 15px; } }
  &_table {
    overflow-x: auto;
    margin-bottom: 20px;
    &-no {
      display: flex;
      justify-content: center;
      color: grey;
      padding: 10px 0;
      font-size: 12px; }
    td, th {
      white-space: nowrap; } } }


// --- login
.login_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; }
.login {
  height: initial;
  max-width: 450px;
  padding:  30px;
  background: #fff;
  &-inp {
    background: transparent; }
  &-top {
    display: flex;
    flex-flow: column;
    justify-items: center;
    align-items: center; }
  &-ava {
    background: #2f353a;
    margin-bottom: 10px; }
  &-form {
    width: 100%; }
  &-submit {
    margin-top: 10px;
    color: #fff; }
  &-footer {
    margin-top: 22px; } }

.files-dropzone_btn {
  font-size: 12px;
  height: 30px; }


// --- window card
.defCard {
  display: flex;
  justify-content: space-between;
  .form-group {
    margin-bottom: 0 !important; }
  &_left {
    width: 100%;
    &_del {
      margin-left: 5px;
      fill: #9f9f9f;
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 0.8; } }
    &_add {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      button {
        background-color: #829ab3;
        font-size: 13px;
        color: #fff;
        &:hover {
          background-color: #1976d2; } } }


    &_row:not(:last-of-type) {
      margin-bottom: 15px; }
    &_row {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      .country-list {
        overflow: hidden !important;
        width: 200px !important; }
      &_fio {
        justify-content: flex-end;
        color: #bbbbbb;
        div:nth-of-type(2) {
          text-decoration: underline; } }

      &_item {
        display: flex;
        align-items: center;
        width: 100%;
        .defCard-ma {
          margin-left: auto;
          margin-right: 0; }
        &_checkbox {
          span {
            font-size: 14px !important; }
          label {
            margin-bottom: 0 !important; } }
        &_title80 {
          min-width: 80px;
          max-width: 80px; }
        &_title100 {
          min-width: 100px;
          max-width: 100px; }
        &_title120 {
          min-width: 120px;
          max-width: 120px; }
        &_title {
          min-width: 150px;
          max-width: 150px; }
        &_title220 {
          min-width: 220px;
          max-width: 220px; }
        &_title570 {
          min-width: 575px;
          max-width: 575px; }
        &_b {
          font-weight: 600;
          color: #000000; }
        .react-tel-input {
          .selected-flag {
            z-index: 0; }
          input {
            width: 100% !important;
            z-index: 0; } }
        p {
          white-space: nowrap;
          margin-right: 10px;
          margin-bottom: 0; } }
      & > div:not(:last-of-type) {
        margin-right: 15px; } } }
  &_right {
    margin-left: 25px;
    min-width: 40%;
    max-width: 40%;
    &_check {
      margin-bottom: 15px;
      label {
        margin-top: 0 !important; } }
    & > textarea {
      margin-bottom: 10px; } } }

.ordersCard_li_icon svg {
  margin-right: 15px; }


.ordersCard {
  display: flex;
  justify-content: space-between;
  .form-group {
    margin-bottom: 0 !important; }
  &_left {
    width: 100%;
    &_del {
      margin-left: 5px;
      fill: #9f9f9f;
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 0.8; } }
    &_add {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      button {
        background-color: #829ab3;
        font-size: 13px;
        color: #fff;
        &:hover {
          background-color: #1976d2; } } }


    &_row:not(:last-of-type) {
      margin-bottom: 15px; }
    &_row {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      .ordersCard_li_icon svg {
        margin-right: 15px; }
      .country-list {
        overflow: hidden !important;
        width: 200px !important; }
      &_fio {
        justify-content: flex-end;
        color: #bbbbbb;
        div:nth-of-type(2) {
          text-decoration: underline; } }
      &_info {
        justify-content: flex-start !important;
        color: #bbbbbb;
        div:nth-of-type(1) {
          min-width: 125px;
          max-width: 125px; } }

      &_item {
        display: flex;
        align-items: center;
        width: 100%;
        &_b {
          font-weight: 600;
          color: #000000; }
        .react-tel-input {
          .selected-flag {
            z-index: 0; }
          input {
            width: 100% !important;
            z-index: 0; } }
        p {
          white-space: nowrap;
          margin-right: 10px;
          margin-bottom: 0; } }
      & > div:not(:last-of-type) {
        margin-right: 15px; } } }
  &_right {
    margin-left: 15px;
    width: 30%;
    &_check {
      margin-bottom: 15px;
      label {
        margin-top: 0 !important; } }
    & > textarea {
      margin-bottom: 10px; } } }

// ---- mdCard_wrap
.mdCard_wrap.act {
  display: flex; }
.mdCard_wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  .mdCard {
    z-index: 999;
    max-width: 800px;
    width: 100%;
    position: relative;
    .MuiBox-root {
      padding: 0; }
    .MuiTypography-body1 {
      font-size: unset; }
    &-closeCard {
      position: absolute;
      right: 5px;
      top: 5px; }
    &-switch {
      margin-right: 15px;
      display: flex;
      align-items: center;
      width: 100%; }
    &-title {
      text-align: center; }
    &-actions {
      display: flex;
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 25px;
      align-items: center; }
    &-agree {
      background: rgb(77, 189, 116);
      color: #fff; }
    &-warn {
      background: rgb(220, 0, 78);
      color: #fff; }
    .ordersCard_left_row {
      align-items: center; }
    &_btnsCh {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e9e9e9;
      &_act {
        color: #fff;
        background-color: #1976d2; } } } }

.noHeight {
  min-height: unset !important; }

.btnsCh_act {
  color: #fff;
  background-color: #1976d2; }

.required {
  font-size: 13px;
  color: #d30a0a; }

// --- mdCard_del
.mdCard_wrap .mdCard_del {
  max-width: 400px;
  min-height: initial;
  div {
    text-align: center; }
  .mdCard-actions {
    width: 100%;
    padding-bottom: 15px;
    padding-left: 24px;
    padding-right: 24px;
    &_btn {
      min-width: 100px !important;
      max-width: 100px !important; } }
  .modalAgree_footer {
    button.modalAgree_footer_ok {
      min-height: 40px;
      min-width: 40px;
      max-width: 40px;
      max-height: 40px; } } }

.cardBtn-marg {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 30px;
  .btnsCh_act {
    color: #757575; }
  button {
    background-color: rgb(245, 245, 245); } }


.filter_card {
	display: flex;
	margin-bottom: 0;
	& > div:not(:last-of-type) {
		width: 180px;
		margin: 0 5px; } }

.passengersBtns {
	display: flex;
	& > div:not(:last-of-type) {
		margin: 0 5px; } }

.GErrorDriver {
  margin-bottom: -20px; }
.GError {
  text-align: center;
  color: $error;
  font-size: 13px;
  span {
    text-decoration: underline;
    font-size: 14px;
    color: #ac0000;
    margin-right: 4px; } }

.sidebar {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  position: relative;
  .nav-bg {
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url('../../assets/img/slider/background2.jpg');
    opacity: 0.07; } }

// --- header

.navbar-toggler {
  min-width: unset !important;
  padding: unset !important; }
.header {
  &-btn {
    color: #555555;
    &:hover {
      color: #2f3539; } }
  &-manager {
    // width: 100%
    display: inline-block; }
  &-icon {
    width: 20px;
    height: 20px; }
  &-notifications {
    position: absolute;
    top: 5px;
    border: 1px solid #fff;
    right: 8px;
    font-size: 9px;
    background: #f44336;
    color:  #fff;
    min-width: 16px;
    height: 16px;
    border-radius: 10px;
    text-align: center;
    line-height: 16px;
    vertical-align: middle;
    display: block; }
  &-linkText {
    z-index: 4;
    font-size: 14px;
    margin: 0px; } }

// --- notif
.relative-wrap {
  position: relative; }
.notif {
  width: 300px;
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 15;
  box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.2), 0px 1px 16px 0px rgba(0,0,0,0.14), 0px 2px 10px -1px rgba(0,0,0,0.12);
  &::after {
    position: absolute;
    top: -20px;
    right: 11px;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 13px solid #fff;
    z-index: 14; }
  &-time {
    font-size: 11px;
    color: grey;
    align-self: flex-end;
    margin-top: -11px; }
  &-item {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    &_row {
      display: flex; } } }

.user-menu {
  // width: 300px
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 15;
  box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.2), 0px 1px 16px 0px rgba(0,0,0,0.14), 0px 2px 10px -1px rgba(0,0,0,0.12);
  &::after {
    position: absolute;
    top: -20px;
    right: 11px;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 13px solid #fff;
    z-index: 14; } }

.logout-row {
  display: flex;
  align-items: center;
  .logout {
    min-width: 17px;
    max-width: 17px;
    margin-right: 5px; } }


// <<< leftbar sidebar (покарываем все костылями. тк эллемент оч негибкий)
$widthLeftbar: 235px !important;
$widthLeftbarUsual: 235px;

html:not([dir="rtl"]) {
  .sidebar {
    width: $widthLeftbar;
    margin-left: -$widthLeftbarUsual; } }
.sidebar .nav, .sidebar .sidebar-nav, .sidebar .nav-bg {
  width: $widthLeftbar; }
@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: $widthLeftbar; } }
@media (min-width: 992px) {
  html:not([dir="rtl"]) {
    .sidebar-lg-show.sidebar-fixed .main {
      margin-left: $widthLeftbar; } } }

html:not([dir="rtl"]) .sidebar-lg-show .sidebar, html:not([dir="rtl"]) .sidebar-show .sidebar {
  margin-left: 0; }

.sidebar {
  .ps__rail-y, .ps__rail-x {
    display: none !important; } }

.sdb-head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0;
  font-size: 22px;
  box-shadow: inset 0 -1px 4px #141414;
  user-select: none;
  position: relative;
  &_link {
    color: unset;
    text-decoration: none !important;
    display: block;
    &:hover {
      color: unset; } }
  &_back {
    z-index: 1101;
    position: absolute;
    right: 5px;
    top: calc(50% - 22px);
    &:hover .sdb-head_back_ic {
      color: #fff; }
    &_ic {
      color: #5b5b5b; } }
  &_2 {
    color: #338138; }
  &_3 {
    position: relative;
    top: -4px;
    color: #338138;
    font-size: 14px; } }

.app-body {
  overflow-x: unset !important; }

.main {
  overflow-x: hidden; }

.sidebarActive {
  height: 100vh !important;
  top: 0  !important;
  z-index: 1099  !important; }

@media (max-width: 991.98px) {
  .sidebar {
    position: fixed !important; }
  .main {
    margin-top: 55px !important; }
  .app-body {
    margin-top: 0 !important; } }
//  leftbar sidebar >>>

.mdCard-phtsRules {
  min-height: unset !important;
  max-width: 550px !important;
  .PhInspectList {
    margin-bottom: 10px;
    max-height: 350px;
    min-height: 350px;
    overflow-y: auto; } }

.photosIns-btns {
  margin-left:  15px;
  display: flex;
  & > button {
    background: #555555;
    color: #fff;
    &:hover {
      opacity: 0.9;
      background: #555555;
      // background: #ffffff
 } }      // color: #000
  &_p {
    max-width: 50px !important;
    min-width: 50px !important;
    svg {
      font-size: 20px; } }
  &_r {
    max-width: 150px !important;
    min-width: 150px !important; } }

// --- ph-card
.ph-card {
  //height: 720px
  overflow-y: auto;
  padding: 20px 50px 0 50px;
  margin-bottom: 10px;
  user-select: none;
  &-closeCard {
    position: fixed;
    top: 5px;
    right: 20px;
    z-index: 11; }
  &_list {
    margin-top: 50px;
    &-hr {
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        margin-left: 10px; }
      &::before {
        margin-right: 10px;
        width: 50px; }
      &::after, &::before {
        content: '';
        min-height: 1px;
        max-height: 1px;
        background: #e4e4e4; }
      span {
        color: rgb(85, 85, 85);
        margin-left: 10px;
        white-space: nowrap; }
      div {
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;
        background: #555555;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%; } }
    li:not(:last-of-type) {
      margin-bottom: 15px; }
    img {
      display: block;
      margin: 0 auto;
      height: 255px;
      object-fit: contain;
      width: 500px; } }
  & > div {
    margin: 0 auto; } }

.driver_phts {
  margin-left: auto;
  margin-right: auto; }
.ordersCard_left_row_item_chbx span {
  font-size: 14px; }
.creatComp_date {
  width: 180px !important; }

.createComp_mr .tarea-row div:not(:last-of-type) {
  margin-right: 20px !important; }

.settingApps_h1 {
  text-align: center;
  margin: 50px 0; }

.h1_companySettings {
  color: #565555;
  margin-bottom: 10px;
  font-size: 26px; }

.settingsApp {
  .defCard_left_row_item {
    justify-content: space-between;
    input {
      border-bottom-left-radius: 0; }
    p {
      padding-right: 15px;
      width: 100%;
      margin-right: 0;
      max-width: 100%;
      line-height: 35px;
      max-height: 35px;
      border-bottom: 1px solid #dadada; } }
  .def-wrap_span {
    max-width: 250px; } }
.btn-grey {
  background: #555555;
  border-color: #555555;
  color: #fff;
  outline: none !important;
  border: none !important;
  border-color: none !important;
  &:hover, &:active {
    opacity: 0.9;
    border-color: #555555 !important;
    background-color: #555555 !important;
    color: #fff !important; } }

// roles
.roles {
  label {
    margin-right: 30px;
    margin-bottom: 0 !important; }
  &_h1 {
    margin: 10px 0;
    span {
      font-weight: 700;
      font-size: 16px; } }
  &_title {
    min-width: 200px;
    max-width: 200px;
    margin-right: 15px; } }

// --- table header
.table_header-th {
  cursor: pointer;
  &:hover svg {
    visibility: initial;
    opacity: 1; }
  svg {
    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;
    width: 14px;
    height: 14px;
    color: #333333; } }

.table_checker {
  cursor: pointer;
  color: #7c7e80;
  &.empty {
    color: #c8ced3; } }

.usersRoles_title {
  font-weight: 600; }
.usersRoles_multi {
  margin-bottom: 20px; }

.header-name_user.act {
  left: 273px; }
.header-name_user {
  transition: all 0.4s;
  position: absolute;
  left: 100px;
  font-size: 14px;
  color: grey;
  top: calc(50% - 7px); }

.filter_driverAudit {
  display: flex;
  .panel-container__start {
    min-width: 200px;
    max-width: 200px;
    margin-left: 10px; }
  &_center {
    margin: 0 10px; } }

// dashboard top
.dashboard {
  padding: 20px 0;
  margin: 0 15px;
  &_middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%  !important;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    & > div {
      padding: 3px;
      background: #FFF;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: 6px;
      width: 100%  !important;
      max-width: 100% !important;
      height: 400px; } }
  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    & > div {
      padding: 3px;
      background: #FFF;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: 6px;
      height: 186px;
      width: 30%;
      max-width: 30%; } } }

.mapGuide {
  padding: 30px;
  &_info {
    text-align: center;
    color: #565555;
    margin-bottom: 30px;
    font-size: 26px; }
  img {
    width: 700px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    display: block; } }


.driver_balance {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  b {
    margin-left: 6px;
    font-weight: 500;
    color: #919191; } }

.td_red {
  background: #c4505094; }
.td_green {
  background: #4c9f4c9e; }

.td_Lgreen {
  background: #4c9f4c30; }
.td_Lred {
  background: #c4505036; }

.select_color {
  position: absolute;
  right: 30px;
  top: calc(50% - 10px);
  border-radius: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid; }

.del_select {
  width: 130px;
  margin-left: 15px;
  .Select-control {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0; }
  .Select-clear {
    display: none !important; } }
.del_select_wrap {
  display: flex;
  justify-content: center;
  align-items: center; }

// --- CSSTransition
.alert-enter {
  opacity: 0;
  transform: scale(0.9); }
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms; }
.alert-exit {
  opacity: 1; }
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms; }


.delOrder {
  overflow: unset;
  .modalAgree p, .modalAgree h6 {
    max-width: 600px; }
  .MuiPaper-root {
    width: 600px;
    overflow: unset; } }

.audit_table {
  position: relative;
  .audit_tblBot {
    position: fixed;
    z-index: 12;
    background: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    tr, th {
      width: 100%; } }
  tbody {
    max-height: 400px;
    overflow-y: auto; } }

.mapTrack_md {
  &_map {
    width: 100% !important;
    height: 70vh  !important;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: #565555;
      text-align: center; }
    & > ymaps {
      width: 100% !important;
      height: 100% !important; } }
  .MuiDialog-paperWidthSm {
    max-width: 70vw;
    width: 100%; } }

// reportTable
.RTW--mb25 {
  margin-bottom: 25px; }
.TBL_DO {
  max-width: 100% !important;
  table {
    thead {
      th:nth-child(1) {
        width: 140px !important; } }
    tbody {
      td:nth-child(1) {
        width: 140px !important; } } } }

.RTW--1f {
  table {
    tr:first-of-type {
      th:first-of-type, td:first-of-type {
        width: 200px; } } } }

.reportTableWrap.audit {
  width: 100%;
  max-width: 90%;
  .ps__rail-y {
    right: 0 !important;
    left: initial !important; }
  .reportTableBody {
    max-height: 50vh;
    .reportTable {
      border: none;
      font-size: 13px; } }
  .reportTableHeader {
    table.reportTable {
      border-bottom: 1px solid rgba(180, 180, 180, 0.54) !important;
      th {
        border: none; } } } }

.reportTableWrap {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px; }
.reportTableHeader {
  table {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    tr:first-child {
      th {
        border-top: none;
        border-left: none; }
      th:last-of-type {
        border-right: none; } } } }
.reportTableBody {
  max-height: 65vh;
  overflow: auto;
  // overflow-x: hidden
 }  // overflow-y: hidden
.toolTable {
  width: unset;
  cursor: help; }
.reportTable {
  width: 100%;
  table-layout: fixed;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: collapse;
  &_none {
    padding: 15px !important;
    border-top: none !important; }
  &_final {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
    border-top: 2px solid #909090; }
  &_driver {
    color: #0a6cbc !important;
    font-weight: 500;
    cursor: pointer;
    td {
      border-bottom: unset !important; } }
  tbody {
    td {
      border: 1px solid #b4b4b48a; } }

  td {
    color: #545454; }
  th {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 600;
    border: 1px solid #9494948a; }
  th, td {
    text-align: center;
    padding: 8px 8px; } }

.headerBlnc {
  margin-right: 5px;
  display: flex;
  align-items: center;
  color: grey;
  border: 1px solid #e5e5e5;
  padding: 6px 12px;
  border-radius: 5px;
  & > div:nth-child(1) {
    font-weight: 500; }
  & > div:nth-child(2) {
    margin-left: 6px; } }

.orderTarifShow.disabled {
  cursor: initial;
  color: rgb(187, 187, 187); }
.orderTarifShow {
  margin-left: 5px;
  cursor: pointer; }

.aside-menu {
  overflow-y: auto; }
.btn.btn-success {
  margin-bottom: 15px; }
.map_info_row {
  border-top: 1px solid #d2d2d2;
  margin: 20px 0;
  & > div {
    display: flex;
    align-items: center;
    label {
      width: 121px;
      font-weight: 500; }
    .map_info_row_inf {
      color: grey;
      margin-left: 8px; } } }

.admPartners_chbx {
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    margin: 0; } }

.settings_h3 {
  color: #565555;
  display: flex;
  font-size: 24px;
  margin: 30px 0;
  align-items: center;
  white-space: nowrap;
  &::before,  &::after {
    background: #363636;
    content: '';
    min-height: 1px;
    max-height: 1px; }
  &::before {
    margin-right: 10px;
    min-width: 30px;
    max-width: 30px; }
  &::after {
    width: 100%;
    margin-left: 10px; } }

// ---- mainTable
.mainTable_p_btn.dis {
  cursor: initial;
  opacity: 0.5; }
.card-body {
  padding-bottom: 0;
  padding-right: 2px;
  padding-left: 2px; }
.mainTable {
  text-align: center;
  max-height: 79vh;
  .rt-thead {
    overflow-x: hidden !important; }
  &_p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    border-top: 2px solid rgba(0,0,0,.1);
    height: 45px;
    &_inp {
      width: 100px;
      text-align: center;
      height: 70%;
      border: 1px solid rgba(0,0,0,.1);
      border-radius: 5px; }
    &_btn {
      display: block;
      width: 25%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0,0,0,.6);
      background: rgba(0,0,0,.1);
      transition: all .1s ease;
      cursor: pointer;
      outline-width: 0; } }
  .rt-noData {
    z-index: 0 !important; }
  .rt-thead.-headerGroups {
    border-bottom: none !important; } }

@media only screen and (max-width: 1600px) and (max-height: 820px) {
  .mainTable {
    max-height: 77vh; } }

@media only screen and (max-width: 1520px) and (max-height: 757px) {
  .mainTable {
    max-height: 75vh; } }

@media only screen and (max-width: 1504px) and (max-height: 678px) {
  .mainTable {
    max-height: 70vh; } }
